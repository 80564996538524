import React from 'react';

import styles from './ProvidentCloudHeader.module.scss';

const ProvidentCloudHeader = () => {
  return (
    <div className={styles.root}>
      <img className={styles.logo}
           alt="ProvidentCloud Logo"
           src="assets/logo/logo.svg" />

      <h1>ProvidentCloud</h1>

      <div className={styles.data}>
        <span>ProvidentCloud B.V.</span>
      </div>

      <div className={styles.data}>
        <span>KvK:&nbsp;</span>
        <span>95824561</span>
      </div>

      <div className={styles.data}>
        <span>BTW-ID:&nbsp;</span>
        <span>NL867323917B01</span>
      </div>
    </div>
  );
};

export default ProvidentCloudHeader;
